.fadeIn {
    opacity: 1; }

.fadeOut {
    opacity: 0; }

.fadeInScale {
    opacity: 1;
    @include transform(scale(1)); }

.nav-absolute {
    position: absolute;
    top: 0px; }

.team-item-active {
    @include transform(translateX(0));
    opacity: 1; }

.show-flex {
    @include display(flex);
    z-index: 9999; }

.menu-item-active {
    color: $cube-salmon; }

.job-arrow-open {
    @include transform(rotateZ(180deg)); }

.jobs-cat-open {
    background-color: $cube-salmon;
    h2, polyline {
        color: white;
        stroke: white; } }

.agentur-main-wrap-height {
    margin-top: 225px !important; }

.nav-fade-in {
    opacity: 1!important;

    top: 0!important; }

.cubeamid {
    background-image: url(../../assets/files/svg/cubamid.svg);
    background-size: cover;
    background-position: center; }

.menu-start-show {
    @include display(flex); }
