@media(max-width: 1400px) {
    .news-item, .team-item {
        height: 19.64vw; } }

@media(max-width: 1370px) {
    .news-item {
        margin-right: 10px;
        &:nth-child(5n) {
            margin-right: 10px; }
        &:nth-child(4n) {
            margin-right: 0; } } }

@media(max-width: 1150px) {
    .case-item-wrap {
        &:nth-child(3n) {
            margin-right: 10px; }
        &:nth-child(2n) {
            margin-right: 0; } } }
@media(max-width: 1100px) {
    .start-credo-item-text {
        width: 80%;
        max-width: none; }

    .start-credo-item-last {
        border-right: 1px solid white !important; }

    .start-credo-item {
        width: 80vw !important;
        border-bottom: 1px solid white;

        text-align: center;
        img {
            margin-left: auto;
            margin-right: auto; } }

    .start-credo-item-inner {
        height: 300px !important; } }

@media(max-width: 1060px) {
    .news-item {
        margin-right: 10px;
        &:nth-child(4n) {
            margin-right: 10px; }
        &:nth-child(3n) {
            margin-right: 0; } } }
@media(max-width: 1050px) {
    .team-flex {
        @include justify-content(center); }
    .team-item {
        width: 35%;
        height: 26.19vw; }

    .main-header {
        font-size: 8rem; }

    .submain-header {
        font-size: 6rem; }

    body, html {
        font-size: 9px; }

    .cube-button {
        font-size: 18px !important;
        line-height: 50px;
        padding: 0 25px !important;
        width: 235px; } }

@media(max-width: 980px) {


    .nav-logo, .open-menu {
        position: relative; }
    .menu {
        position: fixed;
        height: 100vh;
        width: 100vw;
        @include justify-content(center);
        @include flex-direction(column);
        background-color: white;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none; }

    .menu-item {
        clear: both;
        margin: 20px 0; }

    .menu-active-slider {
        display: none; }

    .open-menu, .close-menu, .open-menu-start {
        display: block; } }

@media(max-width: 900px) {
    .table-1 {
        width: 45%; }
    .table-2 {
        width: 55%; } }

@media(max-width: 850px) {
    .team-item {
        width: 46.8%;
        height: 32.35vw; }

    .footer {
        height: 300px; }

    .footer-col-3, .footer-col-2 {
        padding-bottom: 70px; } }

@media(max-width: 750px) {
    .zahlen-item {
        width: 46.72%; }
    .side-padding {
        padding-left: 5%;
        padding-right: 5%; } }
@media(max-width: 700px) {
    .cube-button {
        font-size: 2rem; }

    .team-item {
        width: 232px;
        height: 220px;
        margin: 50px 0 0 0; }

    .team-item-name, .team-item-job {
        @include transform(translateX(0));
        opacity: 1; }

    body, html {
        font-size: 7.25px; } }

@media(max-width: 650px) {
    .xslider-inner-wrap, .xslider-inner-slide, .xslider-loader-inner {
        max-width: 90vw;
        max-height: 90vw; } }

@media(max-width: 500px) {
    body, html {
        font-size: 6.25px; } }









@media(max-width: 1370px) {
    .news-wrap {
        padding-bottom: 30px; } }


@media(max-width: 992px) {
    .jobs-desc, .kontakt-intro {
        margin-bottom: 60px; } }

@media(max-width: 900px) {
    .xslider-text-subtext {
        br {
            display: none; } } }

@media(max-width: 768px) {
    .option-a-wrap, .option-b-wrap, .option-d-wrap {
        margin-bottom: 60px; }

    .option-a-wrap {
        padding-bottom: 0; }

    .option-c-slider {
        padding-bottom: 50px; }





    .intro-bild, .agentur-bg, .main-img {
        height: 50vh;
        min-height: 400px; }

    #kontakt-map {
        min-height: 200px;
        height: 50vh; }

    .agentur-main-wrap {
        margin-top: calc(60vh - 175px); }

    .table-1 {
        width: 100%; }

    .table-2 {
        width: 100%; }

    .padding {
        padding-top: 60px;
        padding-bottom: 40px; }

    .team-inner, .ref-flex {
        padding-bottom: 60px; }

    .padding120 {
        padding-top: 60px;
        padding-bottom: 60px; }

    .cube-button {
        margin-top: 60px; }

    .job-cat-inner {
        padding: 30px 40px; }

    .job-info {
        margin-bottom: 55px; }

    .gf-item {
        width: 100%;
        .team-item {
            max-width: 50vw;
            max-height: 50vw; } }

    .nav {
        height: 100px; }

    .close-menu {
        top: 40px;
        right: 5%; }

    .xslider-text-header {
        margin-bottom: 15px; }

    .xslider-text-subtext {
        margin-top: 0; }

    .impressum-tabs {
        margin-top: 60px; }

    .ref-flex-borderfix {
        bottom: 60px; } }

@media(max-width: 750px) {
    .news-item {
        margin-right: 10px;
        &:nth-child(3n) {
            margin-right: 10px; }
        &:nth-child(2n) {
            margin-right: 0; } } }

@media(max-width: 650px) {
    .case-wrapper {
        .slick-dots {
            bottom: 3vw !important;
            button {
                width: 16px;
                height: 16px;
                &:hover {
                    border-width: 8px; } }
            .slick-active {
                button {
                    border-width: 8px;
                    border-radius: 50%; } } } }
    .option-a-wrap {
        padding-bottom: 30px !important; }
    .option-a-link {
        padding: 25px 30px; }

    .option-c-slider {
        .slick-dots {
            bottom: 60px !important; } }

    .main-header {
        font-size: 8.9vw; }

    .job-item {
        font-size: 3.9vw;
        padding-left: 12vw;
        padding-right: 12vw; }

    .job-list-wrap {
        padding: 60px; }

    .job-info {
        margin-top: 25px; }

    .job-inner {
        h1 {
            margin-bottom: 30px; } }

    .footer-col-1 {
        display: none; }

    .footer-col-2 {
        width: 35%; }

    .footer-col-3 {
        width: 65%; } }

@media(max-width: 600px) {
    .case-nav {
        width: 100%;
        position: relative;

        svg {
            width: 60px;
            height: 60px; } }
    .case-close {
        svg {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto; } }

    .case-prev-next {
        height: 60px;
        @include display(flex);
        @include justify-content(center); }

    .case-prev {
        margin-right: 90px;
        width: 60px;
        height: 60px; }
    .case-next {
        margin-left: 90px;
        width: 60px;
        height: 60px; } }


@media(max-width: 555px) {
    .case-item-wrap {
        &:nth-child(3n) {
            margin-right: 0; }
        &:nth-child(2n) {
            margin-right: 0; }
        &:nth-child(1n) {
            margin-right: 0; } } }

@media(max-width: 550px) {
    .job-cat-title {
        font-size: 5vw; }
    .job-item {
        padding-left: 30px; }
    .job-cat-inner {
        padding-left: 15px;
        padding-right: 15px; }

    .job-arrow {
        width: 20.66px;
        height: 10.66px; }

    .job-item-padding {
        padding: 0px 0 5.5vw 0; }

    .job-list-wrap {
        padding: 10vw;
        margin-bottom: 40px; }

    .gf-xing {
        padding-top: 20px;
        padding-bottom: 25px;
        margin-top: 20px; }

    .gf-name {
        margin-top: 5px; }

    .cube-button {
        line-height: 60px; } }

@media(max-width: 515px) {
    .team-item {
        margin-top: 10px; } }

@media(max-width: 500px) {
    .team-item-name, .team-item-job {
        font-size: 2.2rem; } }
@media(max-width: 450px) {
    .start-credo-item-inner {
        height: 400px !important; }
    .main-subheader {
        margin-top: 30px; }
    .job-list-wrap {
        margin-bottom: 6.66vw; }

    .case-nav {
        width: 100%;
        position: relative;

        svg {
            width: 50px;
            height: 50px; } }
    .case-close {
        svg {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto; } }

    .case-prev-next {
        height: 50px;
        @include display(flex);
        @include justify-content(center); }

    .case-prev {
        margin-right: 70px;
        width: 50px;
        height: 50px; }
    .case-next {
        margin-left: 70px;
        width: 50px;
        height: 50px; } }

@media(max-width: 400px) {
    .nav {
        img {
            width: 65vw;
            height: 6.25vw; } }

    .news-item {
        margin-right: 0;
        &:nth-child(2n) {
            margin-right: 0; }
        &:nth-child(1n) {
            margin-right: 0; } } }

@media(max-height: 600px) {
    .agentur-main-wrap {
        margin-top: 600px; } }
