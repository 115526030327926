.gf-inner {
    padding-top: 105px; }

.gf-slider-wrap {
    position: relative; }

.gf-slide {
    width: 100%;
    height: 100vh;
    max-height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

.gf-slider {
    .slick-dots {
        position: absolute;
        bottom: 30px;
        right: 0;
        left: 0;
        @include display(flex);
        @include justify-content(center);
        .slick-active {
            button {
                background-color: white;
                border-radius: 50%; } } } }

.gf-dot {
    padding: 0;
    background-color: transparent;
    border: 2px solid white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
    &:hover {
        background-color: white; }
    &:focus {
        background-color: transparent; } }

.gf-items {
    @include display(flex);
    @include justify-content(center);
    @include flex-wrap(wrap);
    width: 100%; }


.gf-item {
    margin-top: 40px;
    width: 50%;
    @include display(flex);
    @include flex-direction(column);
    @include align-items(center);
    .team-item {
        width: 275px !important;
        height: 275px !important; } }

.gf-name {
    margin-top: 25px;
    margin-bottom: 5px;
    font-family: 'DIN Next W01 Regular'; }

.gf-xing {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 2px solid $cube-grey-dark;
    width: 84%;
    @include display(flex);
    @include justify-content(center);
    padding-bottom: 50px;
    img {
        width: 30px;
        height: 30px; } }

.gf-text {
    width: 70%;
    margin: 0 auto;
    font-size: 1.8rem; }

.gf-back {
    margin: 120px auto 60px auto; }

