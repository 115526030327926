.stage {
    width: 100%;
    height: 1000px; }

.xslider-wrap {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    @include display(flex);
    @include justify-content(center);
    @include align-items(center);
    z-index: -3;
    background-color: white; }

.xslider-inner {
    opacity: 0;
    @include transition(opacity 200ms);
    @include transition(opacity 200ms);
    position: relative;
    z-index: -2;
    overflow: hidden; }

.xslider-inner-slide {
    width: 66vh !important;
    height: 66vh !important;
    max-width: 500px;
    max-height: 500px;
    svg {
        width: 100%;
        height: 100%; } }

.xslider-outer {
    opacity: 0;
    @include transition(opacity 500ms);
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    overflow: hidden;
    z-index: -3; }

.xslider-outer-slide {

    height: 100vh !important;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }

.xslider-inner-wrap {
    position: relative;
    width: 66vh !important;
    height: 66vh !important;
    max-width: 500px;
    max-height: 500px; }

.actionx-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0; }

.actionx-quad-one, .actionx-quad-two {
    background-color: $cube-grey-dark;
    position: absolute;
    width: 0;
    height: 31.1%;
    @include transition(width 500ms ease-out, transform 500ms);
    margin: 0 auto; }

.actionx-quad-one {
    @include transform(rotateZ(45deg));
    @include transform-origin(0.1% 0%);
    left: 21.875%; }

.actionx-quad-two {
    @include transform(rotateZ(-45deg));
    @include transform-origin(99.9% -0.9%);
    right: 21.875%;
    @include transition-delay(500ms); }

.actionx-start-animation {
    .actionx-quad-one, .actionx-quad-two {
        width: 110.7%; } }

.actionx-outer {
    background-color: $cube-grey-dark;
    position: absolute; }

.actionx-top-left {
    width: 130%;
    height: 100%;
    left: -129%;


    @include transform-origin(100% 50%); }

.actionx-top-right {
    width: 100%;
    height: 130%;
    top: -129%;


    @include transform-origin(50% 100%); }

.actionx-bot-right {
    width: 130%;
    height: 100%;
    right: -129%;

    @include transform-origin(0% 50%); }

.actionx-bot-left {
    width: 100%;
    height: 130%;
    bottom: -129%;


    @include transform-origin(50% 0%); }

.actionx-end-animation {
    .actionx-top-left {
        @include transform(scale(0.5) rotateZ(45deg)); }

    .actionx-top-right {
        @include transform(scale(0.5) rotateZ(-45deg)); } }

.actionx-center {
    position: absolute;
    width: 31.1%;
    height: 31.1%;
    top: 34.45%;
    left: 34.45%;
    @include transform(rotateZ(45deg));
    opacity: 0; }

.actionx-tri-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0;
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,.1); }

.actionx-tri {
    position: absolute;
    width: 100%;
    height: 100%;
    @include transform-origin(50% 50%);
    @include transform(rotateZ(45deg)); }

.actionx-tri-one {
    left: -70.5%;
    background-color: rgb(157, 157, 157); }

.actionx-tri-two {
    top: -70.5%;
    background-color: rgb(103, 103, 103); }

.actionx-tri-three {
    right: -70.5%;
    background-color: rgb(122, 122, 122); }

.actionx-tri-four {
    bottom: -70.5%;
    background-color: rgb(140, 140, 140); }


.xslider-main-image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none; }
.xslider-main-image {
    opacity: 1;    //0

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include transition(opacity 500ms); }

.actionx-center-fill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $cube-grey-dark;
    opacity: 0; }

.xslider-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    @include transform(scale(.9));
    @include transition(opacity 400ms, transform 400ms); }


.xslider-text-inner {
    width: 100%;
    height: 100%;
    max-width: 1220px;
    margin: 0 auto;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    text-align: center; }

.xslider-text-header {
    text-transform: uppercase;
    color: $cube-grey-dark;
    margin-bottom: 30px; }

.start-main-wrap {
    position: relative;
    padding-top: 170px; }

.xslider-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    @include display(flex);
    @include justify-content(center);
    @include align-items(center);
    z-index: 9999; }

.xslider-loader-inner {
    position: relative;
    width: 66vh !important;
    height: 66vh !important;
    max-width: 500px;
    max-height: 500px; }

.start-credo-wrap {
    background-color: white;
    position: relative;
    z-index: 1; }

.start-credo-inner {
    padding-top: 100px;
    h2, .start-credo-text {
        text-align: center; }
    h2 {
        text-transform: uppercase;
        color: $cube-salmon;
        font-weight: normal; } }

.start-credo-text {
    p {
        margin-bottom: 0; } }

.start-credo-items {
    width: 100%;
    @include display(flex);
    @include flex-wrap(wrap); }

.start-credo-item {
    font-family: 'Sabon Next W01 Italic';
    background-color: $cube-grey-dark;
    width: 33.33vw;
    border-right: 1px solid white;


    position: relative; }

.start-credo-item-inner {
    height: 420px !important;
    padding: 60px 20px 45px 20px;
    @include display(flex);
    @include flex-direction(column);
    @include align-items(center); }

.start-credo-item-last {
    border: none !important; }

.start-credo-item-img {
    width: 55px;
    height: 50px;
    margin-bottom: 60px; }

.start-credo-item-text {
    width: 100%;
    max-width: 300px;
    color: white; }

.team {
    background-color: white; }

.team-inner {
    padding-bottom: 120px; }

.team-flex {
    @include display(flex);
    @include flex-wrap(wrap);
    @include justify-content(center); }

.team-item {
    width: 275px;
    height: 275px;
    max-height: 260px;
    max-width: 275px;
    margin: 50px 1.6% 0 1.6%;
    position: relative;
    background-color: white; }


.team-item-overflow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden; }

.team-item-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: auto !important;
    height: 100%;
    bottom: 0; }

.team-item-shadow-outer-one {
    width: 70%;
    height: 70%;
    @include transform(rotateZ(45deg));
    @include transform-origin(0% 0%);
    position: absolute;
    bottom: -10%;
    left: 0;
    box-shadow: 0px -5px 25px 0px rgba(0,0,0,.2);
    background: pink;
    border-top-left-radius: 20%; }

.team-item-shadow-outer-two {
    width: 70%;
    height: 70%;
    @include transform(rotateZ(-45deg));
    @include transform-origin(100% 0%);
    position: absolute;
    bottom: -10%;
    right: 0;
    box-shadow: 0px -5px 5px 0px rgba(0,0,0,.05);
    border-top-right-radius: 20%; }

.team-item-shadow-inner {
    position: absolute;
    top: 0;
    left: -20%;
    bottom: 0;
    right: -20%;
    background-color: white;
    z-index: 5; }

.team-item-description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }

.team-item-name, .team-item-job {
    position: relative;
    z-index: 10;
    color: white;
    font-size: 1.8rem;
    line-height: 3.5rem;
    margin-top: 5px;
    @include display(flex);
    opacity: 0;
    @include transform(translateX(-20px));
    @include transition(transform 800ms, opacity 400ms);
    p {
        margin: 0;
        font-weight: normal;
        white-space: nowrap;
        background-color: $cube-salmon;
        padding: 0 1rem; } }

.team-item-job {
    margin-bottom: 20%;
    margin-left: 40px;
    @include transform(translateX(-20px)); }

.team-a-flex {
    @include display(flex);
    @include justify-content(center);
    @include flex-wrap(wrap); }

.team-skew-start, .team-skew-end {
    display: block; }

.team-skew-start {
    @include triangle(3.5rem, $cube-salmon, up-right); }
.team-skew-end {
    @include triangle(3.5rem, $cube-salmon, down-left); }

.team-item-a {
    cursor: pointer; }

.zahlen {
    width: 100%;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat; }

.zahlen-flex {
    margin-top: 60px;
    @include display(flex);
    @include flex-wrap(wrap);
    @include justify-content(space-between); }

.zahlen-item {
    width: 31.15%;
    margin-bottom: 60px; }

.zahlen-item-img {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 125px; }

.zahlen-item-zahl {
    color: $cube-grey-dark;
    text-align: center;
    line-height: 0.8;
    margin-top: 30px;
    margin-bottom: 15px;
    font-family: 'DIN_1451 LT W01 Mittelschrift'; }

.zahlen-item-desc {
    color: $cube-grey-dark;
    text-align: center;
    line-height: 1.2; }

.ref {
    background-color: $cube-grey-light; }

.ref-flex {
    position: relative;
    @include display(flex);
    @include flex-wrap(wrap);
    padding-bottom: 120px; }

.ref-flex-borderfix {
    position: absolute;
    display: none;
    top: 0;
    right: -2px;
    bottom: 120px;
    left: 0;
    box-shadow: inset -3px -3px 0px 0px $cube-grey-light; }

.ref-item {
    width: 20%;
    height: 13.14vw;
    max-height: 180px;
    @include display(flex);
    @include justify-content(center);
    @include align-items(center);
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    border-right: 1px solid rgba(0, 0, 0, .3);
    &:nth-child(5n) {
        border-right: none; }
    &:nth-last-child(1) {
        border-bottom: none; }
    &:nth-last-child(2) {
        border-bottom: none; }
    &:nth-last-child(3) {
        border-bottom: none; }
    &:nth-last-child(4) {
        border-bottom: none; }
    &:nth-last-child(5) {
        border-bottom: none; } }

.ref-item-logo {
    width: 80%;
    height: 80%;
    max-width: 160px;
    max-height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left; }

.actionx-counter-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include display(flex);
    @include align-items(center);
    @include justify-content(center); }

.actionx-counter {
    font-size: 16px;
    color: $cube-grey-light;
    font-weight: bold; }

.agentur-main-wrap {
    margin-top: calc(100vh - 175px); }

.agentur-bg {
    width: 100%;
    height: 100vh;
    min-height: 600px;
    position: fixed;
    background-position: center;
    background-size: cover;
    top: 0;
    z-index: -2; }

.agentur-text-inner {
    display: none; }

.agentur-text {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include display(flex);
    @include justify-content(center);
    @include align-items(center); }

.agentur-nav {
    position: absolute;
    top: 0; }
