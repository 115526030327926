.main-img {
    width: 100%;
    height: 42.9vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.case-info {

    h1, h2, h3 {
        line-height: .8;
        margin: 0;
        text-align: center;
        color: $cube-grey-dark; }
    h1 {
        margin-bottom: 60px; }
    h2 {
        margin-bottom: 20px;
        font-family: 'DIN Next W01 Regular'; }
    h3 {

        font-family: 'Sabon Next W01 Italic'; } }

.case-desc {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    color: $cube-grey-dark;
    margin-top: 60px; }
.option-a-wrap {
    position: relative;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    margin-bottom: 120px;
    padding-bottom: 60px !important; }
.option-a-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1; }

.option-a-shadow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.option-a-img {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 60px; }


.option-a-link-fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    @include transition(300ms width); }


.option-a-text {
    position: relative;
    z-index: 10;
    font-family: 'DIN_1451 LT W01 Engschrift'; }

.option-a-link {
    @include transition(200ms color);
    text-transform: uppercase;
    padding: 30px 40px;
    border-width: 1px;
    border-style: solid;
    line-height: .8;
    position: relative;

    &:hover {
        .option-a-link-fill {
            width: 100%; } } }


.option-b-wrap {
    margin-bottom: 120px; }

.option-b-slider {
    .slick-dots {
        border-color: inherit;
        position: absolute;
        bottom: 30px;
        right: 0;
        left: 0;
        @include display(flex);
        @include justify-content(center);
        li {
            border-color: inherit; }
        button {
            border-color: inherit; }
        .slick-active {
            button {
                border-width: 10px;
                border-radius: 50%; } } } }

.slider-b-dot {
    padding: 0;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
    &:hover {
        border-width: 10px; }
    &:focus {
        background-color: transparent; } }


.option-e-inner {
    @include display(flex);
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    padding-top: 0; }

.option-e-img {
    width: 49%;
    img {
        display: block;
        margin-bottom: 10px; } }



.option-c-slider {
    padding: 0px 0 140px 0; }

.option-c-slide {
    max-height: 641px;
    max-width: 90vw;
    margin: 0 50px; }

.option-c-slider {
    .slick-dots {
        border-color: inherit;
        position: absolute;
        bottom: 60px;
        right: 0;
        left: 0;
        @include display(flex);
        @include justify-content(center);
        li {
            border-color: inherit; }
        button {
            border-color: inherit; }
        .slick-active {
            button {
                border-width: 10px;
                border-radius: 50%; } } } }



.option-d-wrap {
    margin-bottom: 120px; }
.option-d-slide {
    width: 50vw;
    img {
        width: 100%; } }

.option-d-slider {
    .slick-dots {
        border-color: inherit;
        position: absolute;
        bottom: 30px;
        right: 0;
        left: 0;
        @include display(flex);
        @include justify-content(center);
        li {
            border-color: inherit; }
        button {
            border-color: inherit; }
        .slick-active {
            button {
                border-width: 10px;
                border-radius: 50%; } } } }

.case-nav {
    width: 100%;
    position: relative;
    padding-top: 0;

    svg {
        width: 75px;
        height: 75px; } }



.case-close {
    svg {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        rect, line {
            @include transition(300ms fill, 200ms stroke); } }
    &:hover {
        svg {
            rect {
                fill: #FF7265; }
            line {
                stroke: white; } } } }

.case-prev-next {
    height: 75px;
    @include display(flex);
    @include justify-content(center); }

.case-prev-fill {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 100%;
    background-color: #FF7265;
    @include transition(300ms left); }

.case-prev {
    margin-right: 112.5px;
    width: 75px;
    height: 75px;
    position: relative;
    svg {
        position: relative;
        z-index: 9;
        * {
            @include transition(fill 200ms); } }
    &:hover {
        .case-prev-fill {
            left: 0; }
        svg {
            polyline {
                stroke: white !important; } } } }

.case-next-fill {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    left: 0;
    background-color: #FF7265;
    @include transition(300ms right); }

.case-next {
    margin-left: 112.5px;
    width: 75px;
    height: 75px;
    position: relative;
    svg {
        position: relative;
        z-index: 9;
        * {
            @include transition(fill 200ms); } }
    &:hover {
        .case-next-fill {
            right: 0; }
        svg {
            polyline {
                stroke: white !important; } } } }



.case-wrapper {
    .slick-prev {
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: transparent;
        color: transparent;
        width: 20%;
        &:focus {
            outline: none !important; } }

    .slick-next {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: transparent;
        color: transparent;
        width: 20%;
        &:focus {
            outline: none !important; } } }

