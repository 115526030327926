.impressum-intro {
    text-align: center;
    line-height: 1.5;
    div {
        margin-bottom: 25px;
        text-align: center; }
    a {
        color: $cube-grey-dark; } }

.impressum-tabs {
    margin-top: 120px;

    .job-cat-inner {
        background-color: transparent !important;
        h2 {
            color: $cube-salmon !important; }
        polyline {
            stroke: $cube-salmon !important; }
        &:hover {
            background-color: $cube-salmon !important;
            h2 {
                color: white !important; }
            polyline {
                stroke: white !important; } } }
    .job-item-wrapper {
        text-transform: none;
        background-color: transparent;
        color: $cube-grey-dark !important; }
    .job-item {
        line-height: 1.5;
        color: $cube-grey-dark !important; } }

.nachweis-item {
    padding-top: 0;
    padding-bottom: 0; }

.nachweis-item-wrapper {
    padding: 20px 0; }
