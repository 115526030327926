//colors

$cube-blue: rgb(0, 203, 255);
$cube-green: rgb(156, 255, 133);
$cube-yellow: rgb(255, 255, 0);
$cube-orange: rgb(255, 122, 0);
$cube-pink: rgb(255, 154, 150);
$cube-magenta: rgb(222, 0, 123);
$cube-grey-dark: rgb(65, 65, 65);
$cube-grey-light: rgb(230, 230, 230);
$cube-salmon: rgb(249, 89, 81);
