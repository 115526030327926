.jobs-info {
    text-align: center;
    letter-spacing: .8;
    color: $cube-grey-dark;
    h1 {
        color: $cube-grey-dark; } }
.jobs-list {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    color: $cube-salmon;

    h2 {
        margin: 0; } }

.job-item-wrapper {

    @include transition(max-height 300ms);
    display: block;
    background-color: $cube-salmon; }


.job-item.h-35 {
    line-height: 1.2; }

.job-item {
    color: white;
    padding: 20px 80px;
    line-height: .8;
    display: block;
    &:hover {
        color: white; } }
.job-item-padding {
    padding: 15px 0 60px 0; }


.job-cat {
    text-transform: uppercase;
    border-bottom: 2px solid $cube-salmon;
    h2 {
        margin: 0; } }
.job-cat-inner {

    @include display(flex);
    @include justify-content(space-between);
    @include align-items(center);
    padding: 35px 40px;
    cursor: pointer;
    @include transition(background-color 200ms);
    h2 {

        @include transition(color 200ms); }
    polyline {

        @include transition(stroke 200ms); }
    &:hover {
        background-color: $cube-salmon;
        h2 {
            color: white; }
        polyline {
            stroke: white; } } }
.job-cat-title {
    line-height: 1;
    font-family: 'DIN_1451 LT W01 Mittelschrift'; }

.job-arrow {
    @include transition(transform 300ms);
    width: 31px;
    height: 17px;
    svg {
        width: 100%;
        height: 100%; } }
