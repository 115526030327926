html, body {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 10px;
    color: $cube-grey-dark;
    ::-moz-selection {
        background-color: rgb(230, 230, 230);
        color: rgb(65, 65, 65); }
    ::selection {
        background-color: rgb(230, 230, 230);
        color: rgb(65, 65, 65); } }

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }

body {
    font-family: 'DIN Next W01 Regular'; }

a {
    text-decoration: none; }


* {
    img {
        max-width: 100%;
        height: auto; } }

.subheader-width {
    width: 100%;
    max-width: 800px;
    margin: auto auto; }
