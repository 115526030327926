.nav {
    width: 100%;
    height: 175px;
    position: relative;
    top: 0px;
    z-index: 9999; }

.nav-start {
    position: fixed;
    @include transition(opacity 400ms); }

.nav-inner {
    width: 100%;
    height: 100%;
    @include display(flex);
    @include align-items(center);
    @include justify-content(space-between);

    img {
        width: 260px;
        height: 25px; } }
.menu {
    position: relative;
    height: 100%;
    @include display(flex);
    @include align-items(center); }

.menu-item {
    float: left;
    color: $cube-grey-dark;
    text-transform: uppercase;
    margin-left: 25px;
    @include transition(color 300ms);
    font-family: 'DIN_1451 LT W01 Mittelschrift';
    &:hover {
        color: $cube-salmon; } }
.menu-active-slider {
    width: calc(100% + 25px);
    position: absolute;
    top: 0;
    right: 0;
    height: 5px; }

.menu-active-slide {
    width: 40px;
    height: 100%;
    background-color: $cube-grey-dark;
    @include transition(left 500ms, width 500ms); }

.open-menu {
    width: 25px;
    height: 25px;
    background: none !important;
    border-radius: 0;
    padding: 0;
    display: none;
    svg {
        width: 100%;
        height: 100%; } }

.close-menu {
    position: absolute;
    top: 80px;
    right: 70px;
    background: none !important;
    border-radius: 0;
    padding: 0;
    display: none; }

