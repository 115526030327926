button, input[type="button"], input[type="reset"], input[type="submit"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  background-color: #477dca;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus,
  button:hover,
  button:focus {
    background-color: #2c5999;
    color: #fff; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled,
  button:disabled {
    cursor: not-allowed;
    opacity: 0.5; }

fieldset {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  display: block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }
  label.required::after {
    content: "*"; }
  label abbr {
    display: none; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea,
select[multiple=multiple],
textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, textarea:hover,
  select[multiple=multiple]:hover,
  textarea:hover {
    border-color: #c4c4c4; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, textarea:focus,
  select[multiple=multiple]:focus,
  textarea:focus {
    border-color: #477dca;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(55, 112, 192, 0.7);
    outline: none; }

textarea {
  resize: vertical; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

input[type="file"] {
  padding-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 1.5em;
  max-width: 100%;
  width: auto; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

table {
  -webkit-font-feature-settings: "kern", "liga", "tnum";
  -moz-font-feature-settings: "kern", "liga", "tnum";
  -ms-font-feature-settings: "kern", "liga", "tnum";
  font-feature-settings: "kern", "liga", "tnum";
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #b7b7b7;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  -webkit-font-feature-settings: "kern", "liga", "pnum";
  -moz-font-feature-settings: "kern", "liga", "pnum";
  -ms-font-feature-settings: "kern", "liga", "pnum";
  font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  color: #333;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #477dca;
  text-decoration: none;
  transition: color 0.1s linear; }
  a:active, a:focus, a:hover {
    color: #2c5999; }
  a:active, a:focus {
    outline: none; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

html, body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 10px;
  color: #414141; }
  html ::-moz-selection, body ::-moz-selection {
    background-color: #e6e6e6;
    color: #414141; }
  html ::selection, body ::selection {
    background-color: #e6e6e6;
    color: #414141; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

body {
  font-family: 'DIN Next W01 Regular'; }

a {
  text-decoration: none; }

* img {
  max-width: 100%;
  height: auto; }

.subheader-width {
  width: 100%;
  max-width: 800px;
  margin: auto auto; }

.nav-font {
  font-size: 18px; }

.main-header {
  font-size: 8rem;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  color: #f95951;
  font-weight: normal;
  margin: 0 0 30px 0;
  font-family: 'DIN_1451 LT W01 Engschrift'; }

.submain-header {
  font-size: 8rem;
  line-height: 1.2;
  margin: 0;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: #414141;
  font-family: 'DIN_1451 LT W01 Engschrift'; }

.main-subheader {
  font-size: 1.8rem;
  line-height: 1.5;
  text-align: center;
  margin-top: 40px; }
  .main-subheader p {
    margin: 0;
    color: #414141; }

.h-35 {
  font-size: 3.5rem; }

.h-small {
  font-size: 4rem; }

.h-smaller {
  font-size: 2.6rem; }

.h-smallest {
  font-size: 2rem; }

.p-normal {
  font-size: 1.8rem;
  line-height: 1.5; }

.p-smaller {
  font-size: 2rem;
  line-height: 1.5; }

.main-img {
  width: 100%;
  height: 42.9vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.case-info h1, .case-info h2, .case-info h3 {
  line-height: .8;
  margin: 0;
  text-align: center;
  color: #414141; }

.case-info h1 {
  margin-bottom: 60px; }

.case-info h2 {
  margin-bottom: 20px;
  font-family: 'DIN Next W01 Regular'; }

.case-info h3 {
  font-family: 'Sabon Next W01 Italic'; }

.case-desc {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  color: #414141;
  margin-top: 60px; }

.option-a-wrap {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 120px;
  padding-bottom: 60px !important; }

.option-a-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1; }

.option-a-shadow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.option-a-img {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 60px; }

.option-a-link-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0%;
  -webkit-transition: 300ms width;
  -moz-transition: 300ms width;
  transition: 300ms width; }

.option-a-text {
  position: relative;
  z-index: 10;
  font-family: 'DIN_1451 LT W01 Engschrift'; }

.option-a-link {
  -webkit-transition: 200ms color;
  -moz-transition: 200ms color;
  transition: 200ms color;
  text-transform: uppercase;
  padding: 30px 40px;
  border-width: 1px;
  border-style: solid;
  line-height: .8;
  position: relative; }
  .option-a-link:hover .option-a-link-fill {
    width: 100%; }

.option-b-wrap {
  margin-bottom: 120px; }

.option-b-slider .slick-dots {
  border-color: inherit;
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .option-b-slider .slick-dots li {
    border-color: inherit; }
  .option-b-slider .slick-dots button {
    border-color: inherit; }
  .option-b-slider .slick-dots .slick-active button {
    border-width: 10px;
    border-radius: 50%; }

.slider-b-dot {
  padding: 0;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px; }
  .slider-b-dot:hover {
    border-width: 10px; }
  .slider-b-dot:focus {
    background-color: transparent; }

.option-e-inner {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
  padding-top: 0; }

.option-e-img {
  width: 49%; }
  .option-e-img img {
    display: block;
    margin-bottom: 10px; }

.option-c-slider {
  padding: 0px 0 140px 0; }

.option-c-slide {
  max-height: 641px;
  max-width: 90vw;
  margin: 0 50px; }

.option-c-slider .slick-dots {
  border-color: inherit;
  position: absolute;
  bottom: 60px;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .option-c-slider .slick-dots li {
    border-color: inherit; }
  .option-c-slider .slick-dots button {
    border-color: inherit; }
  .option-c-slider .slick-dots .slick-active button {
    border-width: 10px;
    border-radius: 50%; }

.option-d-wrap {
  margin-bottom: 120px; }

.option-d-slide {
  width: 50vw; }
  .option-d-slide img {
    width: 100%; }

.option-d-slider .slick-dots {
  border-color: inherit;
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .option-d-slider .slick-dots li {
    border-color: inherit; }
  .option-d-slider .slick-dots button {
    border-color: inherit; }
  .option-d-slider .slick-dots .slick-active button {
    border-width: 10px;
    border-radius: 50%; }

.case-nav {
  width: 100%;
  position: relative;
  padding-top: 0; }
  .case-nav svg {
    width: 75px;
    height: 75px; }

.case-close svg {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto; }
  .case-close svg rect, .case-close svg line {
    -webkit-transition: 300ms fill, 200ms stroke;
    -moz-transition: 300ms fill, 200ms stroke;
    transition: 300ms fill, 200ms stroke; }

.case-close:hover svg rect {
  fill: #FF7265; }

.case-close:hover svg line {
  stroke: white; }

.case-prev-next {
  height: 75px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

.case-prev-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 100%;
  background-color: #FF7265;
  -webkit-transition: 300ms left;
  -moz-transition: 300ms left;
  transition: 300ms left; }

.case-prev {
  margin-right: 112.5px;
  width: 75px;
  height: 75px;
  position: relative; }
  .case-prev svg {
    position: relative;
    z-index: 9; }
    .case-prev svg * {
      -webkit-transition: fill 200ms;
      -moz-transition: fill 200ms;
      transition: fill 200ms; }
  .case-prev:hover .case-prev-fill {
    left: 0; }
  .case-prev:hover svg polyline {
    stroke: white !important; }

.case-next-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  left: 0;
  background-color: #FF7265;
  -webkit-transition: 300ms right;
  -moz-transition: 300ms right;
  transition: 300ms right; }

.case-next {
  margin-left: 112.5px;
  width: 75px;
  height: 75px;
  position: relative; }
  .case-next svg {
    position: relative;
    z-index: 9; }
    .case-next svg * {
      -webkit-transition: fill 200ms;
      -moz-transition: fill 200ms;
      transition: fill 200ms; }
  .case-next:hover .case-next-fill {
    right: 0; }
  .case-next:hover svg polyline {
    stroke: white !important; }

.case-wrapper .slick-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: transparent;
  color: transparent;
  width: 20%; }
  .case-wrapper .slick-prev:focus {
    outline: none !important; }

.case-wrapper .slick-next {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  color: transparent;
  width: 20%; }
  .case-wrapper .slick-next:focus {
    outline: none !important; }

.cases {
  padding-bottom: 110px; }

.cases-inner {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%; }

.case-item {
  position: relative;
  cursor: pointer;
  width: 100%; }
  .case-item img {
    display: block;
    width: 100%; }

.case-item-wrap {
  width: 30%;
  margin-bottom: 10px;
  margin-right: 10px; }
  .case-item-wrap:nth-child(3n) {
    margin-right: 0; }

.case-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms;
  color: white;
  text-transform: uppercase;
  line-height: .8;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding: 8%; }
  .case-item-overlay h2 {
    margin-bottom: 2.63%;
    font-family: 'DIN_1451 LT W01 Engschrift'; }
  .case-item-overlay div {
    line-height: 1.2;
    text-transform: none; }

.case-item-hover:hover .case-item-overlay {
  opacity: 1; }

.footer {
  width: 100%;
  height: 380px;
  background-color: #414141; }

.footer-inner {
  width: 100%;
  height: 100%;
  max-width: 1220px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.footer-col {
  width: 33.33%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }
  .footer-col a {
    color: white; }
  .footer-col li {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 2;
    font-weight: 500;
    font-family: 'DIN_1451 LT W01 Mittelschrift'; }

.footer-logo {
  width: 146px;
  height: 15px; }

.footer-socials {
  margin-bottom: 45px; }
  .footer-socials li {
    float: left;
    margin-left: 20px; }

.footer-col-2 {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
  padding-bottom: 110px; }

.footer-col-3 {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
  padding-bottom: 110px;
  -webkit-box-align: end;
  -moz-box-align: end;
  box-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
  align-items: flex-end;
  -ms-flex-align: end; }

.footer-info {
  text-align: right;
  color: white;
  font-size: 14px;
  color: #8c8c8c; }
  .footer-info a {
    color: #8c8c8c; }
    .footer-info a:hover {
      color: #f95951; }

.gf-inner {
  padding-top: 105px; }

.gf-slider-wrap {
  position: relative; }

.gf-slide {
  width: 100%;
  height: 100vh;
  max-height: 700px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.gf-slider .slick-dots {
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .gf-slider .slick-dots .slick-active button {
    background-color: white;
    border-radius: 50%; }

.gf-dot {
  padding: 0;
  background-color: transparent;
  border: 2px solid white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px; }
  .gf-dot:hover {
    background-color: white; }
  .gf-dot:focus {
    background-color: transparent; }

.gf-items {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%; }

.gf-item {
  margin-top: 40px;
  width: 50%;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }
  .gf-item .team-item {
    width: 275px !important;
    height: 275px !important; }

.gf-name {
  margin-top: 25px;
  margin-bottom: 5px;
  font-family: 'DIN Next W01 Regular'; }

.gf-xing {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 2px solid #414141;
  width: 84%;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding-bottom: 50px; }
  .gf-xing img {
    width: 30px;
    height: 30px; }

.gf-text {
  width: 70%;
  margin: 0 auto;
  font-size: 1.8rem; }

.gf-back {
  margin: 120px auto 60px auto; }

.nav {
  width: 100%;
  height: 175px;
  position: relative;
  top: 0px;
  z-index: 9999; }

.nav-start {
  position: fixed;
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  transition: opacity 400ms; }

.nav-inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .nav-inner img {
    width: 260px;
    height: 25px; }

.menu {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }

.menu-item {
  float: left;
  color: #414141;
  text-transform: uppercase;
  margin-left: 25px;
  -webkit-transition: color 300ms;
  -moz-transition: color 300ms;
  transition: color 300ms;
  font-family: 'DIN_1451 LT W01 Mittelschrift'; }
  .menu-item:hover {
    color: #f95951; }

.menu-active-slider {
  width: calc(100% + 25px);
  position: absolute;
  top: 0;
  right: 0;
  height: 5px; }

.menu-active-slide {
  width: 40px;
  height: 100%;
  background-color: #414141;
  -webkit-transition: left 500ms, width 500ms;
  -moz-transition: left 500ms, width 500ms;
  transition: left 500ms, width 500ms; }

.open-menu {
  width: 25px;
  height: 25px;
  background: none !important;
  border-radius: 0;
  padding: 0;
  display: none; }
  .open-menu svg {
    width: 100%;
    height: 100%; }

.close-menu {
  position: absolute;
  top: 80px;
  right: 70px;
  background: none !important;
  border-radius: 0;
  padding: 0;
  display: none; }

.impressum-intro {
  text-align: center;
  line-height: 1.5; }
  .impressum-intro div {
    margin-bottom: 25px;
    text-align: center; }
  .impressum-intro a {
    color: #414141; }

.impressum-tabs {
  margin-top: 120px; }
  .impressum-tabs .job-cat-inner {
    background-color: transparent !important; }
    .impressum-tabs .job-cat-inner h2 {
      color: #f95951 !important; }
    .impressum-tabs .job-cat-inner polyline {
      stroke: #f95951 !important; }
    .impressum-tabs .job-cat-inner:hover {
      background-color: #f95951 !important; }
      .impressum-tabs .job-cat-inner:hover h2 {
        color: white !important; }
      .impressum-tabs .job-cat-inner:hover polyline {
        stroke: white !important; }
  .impressum-tabs .job-item-wrapper {
    text-transform: none;
    background-color: transparent;
    color: #414141 !important; }
  .impressum-tabs .job-item {
    line-height: 1.5;
    color: #414141 !important; }

.nachweis-item {
  padding-top: 0;
  padding-bottom: 0; }

.nachweis-item-wrapper {
  padding: 20px 0; }

.job-inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 110px; }
  .job-inner .submain-header {
    margin-bottom: 45px; }
  .job-inner h1, .job-inner h2 {
    text-align: center;
    line-height: 1;
    text-transform: uppercase; }
  .job-inner h1 {
    margin-bottom: 55px; }
  .job-inner h2 {
    margin-bottom: 15px;
    font-family: 'DIN Next W01 Regular';
    text-transform: none; }
  .job-inner h3 {
    text-transform: uppercase; }

.job-date {
  line-height: .8;
  font-style: italic;
  text-align: center;
  font-family: 'Sabon Next W01 Italic'; }

.job-info {
  margin-top: 50px;
  margin-bottom: 110px; }

.job-list-wrap {
  background-color: #f95951;
  width: 100%;
  color: white;
  padding: 80px;
  margin-bottom: 60px; }
  .job-list-wrap a {
    color: white; }
    .job-list-wrap a:hover {
      text-decoration: underline; }
  .job-list-wrap h3 {
    font-family: 'DIN_1451 LT W01 Engschrift'; }

.job-list {
  list-style: disc;
  margin-left: 1.8rem; }

.job-back {
  color: #f95951; }

.jobs-info {
  text-align: center;
  letter-spacing: .8;
  color: #414141; }
  .jobs-info h1 {
    color: #414141; }

.jobs-list {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  color: #f95951; }
  .jobs-list h2 {
    margin: 0; }

.job-item-wrapper {
  -webkit-transition: max-height 300ms;
  -moz-transition: max-height 300ms;
  transition: max-height 300ms;
  display: block;
  background-color: #f95951; }

.job-item.h-35 {
  line-height: 1.2; }

.job-item {
  color: white;
  padding: 20px 80px;
  line-height: .8;
  display: block; }
  .job-item:hover {
    color: white; }

.job-item-padding {
  padding: 15px 0 60px 0; }

.job-cat {
  text-transform: uppercase;
  border-bottom: 2px solid #f95951; }
  .job-cat h2 {
    margin: 0; }

.job-cat-inner {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  padding: 35px 40px;
  cursor: pointer;
  -webkit-transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  transition: background-color 200ms; }
  .job-cat-inner h2 {
    -webkit-transition: color 200ms;
    -moz-transition: color 200ms;
    transition: color 200ms; }
  .job-cat-inner polyline {
    -webkit-transition: stroke 200ms;
    -moz-transition: stroke 200ms;
    transition: stroke 200ms; }
  .job-cat-inner:hover {
    background-color: #f95951; }
    .job-cat-inner:hover h2 {
      color: white; }
    .job-cat-inner:hover polyline {
      stroke: white; }

.job-cat-title {
  line-height: 1;
  font-family: 'DIN_1451 LT W01 Mittelschrift'; }

.job-arrow {
  -webkit-transition: -webkit-transform 300ms;
  -moz-transition: -moz-transform 300ms;
  transition: transform 300ms;
  width: 31px;
  height: 17px; }
  .job-arrow svg {
    width: 100%;
    height: 100%; }

.kontakt-intro {
  text-align: center;
  margin-bottom: 100px; }

.kontakt-back {
  margin-top: 100px; }

.table-wrap {
  max-width: 1000px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .table-wrap td {
    border: none;
    font-size: 2.4rem;
    padding: 0; }

.table-1 {
  width: 55%; }

.table-2 {
  width: 45%; }

.col-3 {
  width: 67.5%; }
  .col-3 a {
    color: #f95951; }

.kontakt .padding120 {
  padding-top: 100px; }

.clip-svg {
  position: absolute; }

.max-width {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto; }

.max-width-flex {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto; }

.padding {
  padding: 120px 0 60px 0; }

.padding120 {
  padding: 120px 0; }

.side-padding {
  padding: 0 70px; }

.fortschritt {
  height: 5px;
  background-color: #f95951;
  width: 0%;
  position: fixed;
  top: 0;
  z-index: 99999; }

.home-fortschritt {
  height: 5px;
  background-color: #f95951;
  width: 0%;
  position: fixed;
  top: 0;
  z-index: 99999; }

.cube-button {
  display: block;
  color: #f95951;
  width: 275px;
  font-size: 2rem;
  line-height: 75px;
  padding: 0 45px;
  border: 1px solid #f95951;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  -webkit-transition: color 400ms;
  -moz-transition: color 400ms;
  transition: color 400ms;
  margin: 120px auto 0 auto;
  font-family: 'DIN_1451 LT W01 Engschrift'; }
  .cube-button span {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 0%;
    background-color: #f95951;
    -webkit-transition: width 400ms;
    -moz-transition: width 400ms;
    transition: width 400ms; }
  .cube-button div {
    position: relative;
    z-index: 99;
    text-align: center; }
  .cube-button:hover {
    color: white !important; }
    .cube-button:hover span {
      width: 100%; }
  .cube-button:focus {
    color: #f95951; }

.intro-text {
  margin: auto auto 120px auto; }

.intro-bild {
  width: 100%;
  height: 42.86vw;
  max-height: 771px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.start-main-wrap {
  z-index: 99; }
  .start-main-wrap nav {
    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    transition: opacity 300ms;
    position: fixed;
    top: -100px;
    opacity: 0;
    z-index: 0; }

.menu-start {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  display: none; }

.close-menu-start {
  position: absolute;
  top: 35px;
  right: 5%;
  background: none !important;
  border-radius: 0;
  padding: 0; }

.open-menu-start {
  width: 25px;
  height: 25px;
  background: none !important;
  border-radius: 0;
  padding: 0;
  display: none; }
  .open-menu-start svg {
    width: 100%;
    height: 100%; }

.news-wrap {
  width: 100%;
  padding-bottom: 110px; }

.news {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%; }

.news-item {
  width: 21.82%;
  height: 275px;
  position: relative;
  display: block;
  margin-bottom: 10px;
  margin-right: 10px; }
  .news-item:nth-child(5n) {
    margin-right: 0; }
  .news-item:hover .news-item-overlay {
    opacity: 1; }

.news-item-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.news-item-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms; }

.fb-news {
  width: 18.6%;
  height: 18.6%; }

.fb-loader-wrap {
  margin: 20vh auto 20vh auto;
  width: 75px;
  height: 75px; }
  .fb-loader-wrap svg {
    width: 100%;
    height: 100%; }

.fb-loader path {
  -webkit-animation: fb-loading 2s ease-in infinite;
  -moz-animation: fb-loading 2s ease-in infinite;
  animation: fb-loading 2s ease-in infinite; }

.news-loading {
  width: 100%; }

@-webkit-keyframes loading {
  from {
    -webkit-transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(500%); } }

@-moz-keyframes loading {
  from {
    -moz-transform: translateX(-100%); }
  to {
    -moz-transform: translateX(500%); } }

@keyframes loading {
  from {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(500%);
    -moz-transform: translateX(500%);
    -ms-transform: translateX(500%);
    -o-transform: translateX(500%);
    transform: translateX(500%); } }

@-webkit-keyframes fb-loading {
  from {
    fill: #f95951; }
  50% {
    fill: white; }
  to {
    fill: #f95951; } }

@-moz-keyframes fb-loading {
  from {
    fill: #f95951; }
  50% {
    fill: white; }
  to {
    fill: #f95951; } }

@keyframes fb-loading {
  from {
    fill: #f95951; }
  50% {
    fill: white; }
  to {
    fill: #f95951; } }

.stage {
  width: 100%;
  height: 1000px; }

.xslider-wrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  z-index: -3;
  background-color: white; }

.xslider-inner {
  opacity: 0;
  -webkit-transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  transition: opacity 200ms;
  -webkit-transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  transition: opacity 200ms;
  position: relative;
  z-index: -2;
  overflow: hidden; }

.xslider-inner-slide {
  width: 66vh !important;
  height: 66vh !important;
  max-width: 500px;
  max-height: 500px; }
  .xslider-inner-slide svg {
    width: 100%;
    height: 100%; }

.xslider-outer {
  opacity: 0;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  overflow: hidden;
  z-index: -3; }

.xslider-outer-slide {
  height: 100vh !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.xslider-inner-wrap {
  position: relative;
  width: 66vh !important;
  height: 66vh !important;
  max-width: 500px;
  max-height: 500px; }

.actionx-wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; }

.actionx-quad-one, .actionx-quad-two {
  background-color: #414141;
  position: absolute;
  width: 0;
  height: 31.1%;
  -webkit-transition: width 500ms ease-out, -webkit-transform 500ms;
  -moz-transition: width 500ms ease-out, -moz-transform 500ms;
  transition: width 500ms ease-out, transform 500ms;
  margin: 0 auto; }

.actionx-quad-one {
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  -webkit-transform-origin: 0.1% 0%;
  -moz-transform-origin: 0.1% 0%;
  -ms-transform-origin: 0.1% 0%;
  -o-transform-origin: 0.1% 0%;
  transform-origin: 0.1% 0%;
  left: 21.875%; }

.actionx-quad-two {
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
  -webkit-transform-origin: 99.9% -0.9%;
  -moz-transform-origin: 99.9% -0.9%;
  -ms-transform-origin: 99.9% -0.9%;
  -o-transform-origin: 99.9% -0.9%;
  transform-origin: 99.9% -0.9%;
  right: 21.875%;
  -webkit-transition-delay: 500ms;
  -moz-transition-delay: 500ms;
  transition-delay: 500ms; }

.actionx-start-animation .actionx-quad-one, .actionx-start-animation .actionx-quad-two {
  width: 110.7%; }

.actionx-outer {
  background-color: #414141;
  position: absolute; }

.actionx-top-left {
  width: 130%;
  height: 100%;
  left: -129%;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%; }

.actionx-top-right {
  width: 100%;
  height: 130%;
  top: -129%;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%; }

.actionx-bot-right {
  width: 130%;
  height: 100%;
  right: -129%;
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  transform-origin: 0% 50%; }

.actionx-bot-left {
  width: 100%;
  height: 130%;
  bottom: -129%;
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%; }

.actionx-end-animation .actionx-top-left {
  -webkit-transform: scale(0.5) rotateZ(45deg);
  -moz-transform: scale(0.5) rotateZ(45deg);
  -ms-transform: scale(0.5) rotateZ(45deg);
  -o-transform: scale(0.5) rotateZ(45deg);
  transform: scale(0.5) rotateZ(45deg); }

.actionx-end-animation .actionx-top-right {
  -webkit-transform: scale(0.5) rotateZ(-45deg);
  -moz-transform: scale(0.5) rotateZ(-45deg);
  -ms-transform: scale(0.5) rotateZ(-45deg);
  -o-transform: scale(0.5) rotateZ(-45deg);
  transform: scale(0.5) rotateZ(-45deg); }

.actionx-center {
  position: absolute;
  width: 31.1%;
  height: 31.1%;
  top: 34.45%;
  left: 34.45%;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  opacity: 0; }

.actionx-tri-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }

.actionx-tri {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg); }

.actionx-tri-one {
  left: -70.5%;
  background-color: #9d9d9d; }

.actionx-tri-two {
  top: -70.5%;
  background-color: #676767; }

.actionx-tri-three {
  right: -70.5%;
  background-color: #7a7a7a; }

.actionx-tri-four {
  bottom: -70.5%;
  background-color: #8c8c8c; }

.xslider-main-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none; }

.xslider-main-image {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms; }

.actionx-center-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #414141;
  opacity: 0; }

.xslider-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: opacity 400ms, -webkit-transform 400ms;
  -moz-transition: opacity 400ms, -moz-transform 400ms;
  transition: opacity 400ms, transform 400ms; }

.xslider-text-inner {
  width: 100%;
  height: 100%;
  max-width: 1220px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  text-align: center; }

.xslider-text-header {
  text-transform: uppercase;
  color: #414141;
  margin-bottom: 30px; }

.start-main-wrap {
  position: relative;
  padding-top: 170px; }

.xslider-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  z-index: 9999; }

.xslider-loader-inner {
  position: relative;
  width: 66vh !important;
  height: 66vh !important;
  max-width: 500px;
  max-height: 500px; }

.start-credo-wrap {
  background-color: white;
  position: relative;
  z-index: 1; }

.start-credo-inner {
  padding-top: 100px; }
  .start-credo-inner h2, .start-credo-inner .start-credo-text {
    text-align: center; }
  .start-credo-inner h2 {
    text-transform: uppercase;
    color: #f95951;
    font-weight: normal; }

.start-credo-text p {
  margin-bottom: 0; }

.start-credo-items {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.start-credo-item {
  font-family: 'Sabon Next W01 Italic';
  background-color: #414141;
  width: 33.33vw;
  border-right: 1px solid white;
  position: relative; }

.start-credo-item-inner {
  height: 420px !important;
  padding: 60px 20px 45px 20px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }

.start-credo-item-last {
  border: none !important; }

.start-credo-item-img {
  width: 55px;
  height: 50px;
  margin-bottom: 60px; }

.start-credo-item-text {
  width: 100%;
  max-width: 300px;
  color: white; }

.team {
  background-color: white; }

.team-inner {
  padding-bottom: 120px; }

.team-flex {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

.team-item {
  width: 275px;
  height: 275px;
  max-height: 260px;
  max-width: 275px;
  margin: 50px 1.6% 0 1.6%;
  position: relative;
  background-color: white; }

.team-item-overflow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden; }

.team-item-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: auto !important;
  height: 100%;
  bottom: 0; }

.team-item-shadow-outer-one {
  width: 70%;
  height: 70%;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -o-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  position: absolute;
  bottom: -10%;
  left: 0;
  box-shadow: 0px -5px 25px 0px rgba(0, 0, 0, 0.2);
  background: pink;
  border-top-left-radius: 20%; }

.team-item-shadow-outer-two {
  width: 70%;
  height: 70%;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
  -webkit-transform-origin: 100% 0%;
  -moz-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -o-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  position: absolute;
  bottom: -10%;
  right: 0;
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.05);
  border-top-right-radius: 20%; }

.team-item-shadow-inner {
  position: absolute;
  top: 0;
  left: -20%;
  bottom: 0;
  right: -20%;
  background-color: white;
  z-index: 5; }

.team-item-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }

.team-item-name, .team-item-job {
  position: relative;
  z-index: 10;
  color: white;
  font-size: 1.8rem;
  line-height: 3.5rem;
  margin-top: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  -o-transform: translateX(-20px);
  transform: translateX(-20px);
  -webkit-transition: -webkit-transform 800ms, opacity 400ms;
  -moz-transition: -moz-transform 800ms, opacity 400ms;
  transition: transform 800ms, opacity 400ms; }
  .team-item-name p, .team-item-job p {
    margin: 0;
    font-weight: normal;
    white-space: nowrap;
    background-color: #f95951;
    padding: 0 1rem; }

.team-item-job {
  margin-bottom: 20%;
  margin-left: 40px;
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  -o-transform: translateX(-20px);
  transform: translateX(-20px); }

.team-a-flex {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.team-skew-start, .team-skew-end {
  display: block; }

.team-skew-start {
  height: 0;
  width: 0;
  border-top: 3.5rem solid #f95951;
  border-left: 3.5rem solid transparent; }

.team-skew-end {
  height: 0;
  width: 0;
  border-bottom: 3.5rem solid #f95951;
  border-right: 3.5rem solid transparent; }

.team-item-a {
  cursor: pointer; }

.zahlen {
  width: 100%;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat; }

.zahlen-flex {
  margin-top: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }

.zahlen-item {
  width: 31.15%;
  margin-bottom: 60px; }

.zahlen-item-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 125px; }

.zahlen-item-zahl {
  color: #414141;
  text-align: center;
  line-height: 0.8;
  margin-top: 30px;
  margin-bottom: 15px;
  font-family: 'DIN_1451 LT W01 Mittelschrift'; }

.zahlen-item-desc {
  color: #414141;
  text-align: center;
  line-height: 1.2; }

.ref {
  background-color: #e6e6e6; }

.ref-flex {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 120px; }

.ref-flex-borderfix {
  position: absolute;
  display: none;
  top: 0;
  right: -2px;
  bottom: 120px;
  left: 0;
  box-shadow: inset -3px -3px 0px 0px #e6e6e6; }

.ref-item {
  width: 20%;
  height: 13.14vw;
  max-height: 180px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3); }
  .ref-item:nth-child(5n) {
    border-right: none; }
  .ref-item:nth-last-child(1) {
    border-bottom: none; }
  .ref-item:nth-last-child(2) {
    border-bottom: none; }
  .ref-item:nth-last-child(3) {
    border-bottom: none; }
  .ref-item:nth-last-child(4) {
    border-bottom: none; }
  .ref-item:nth-last-child(5) {
    border-bottom: none; }

.ref-item-logo {
  width: 80%;
  height: 80%;
  max-width: 160px;
  max-height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left; }

.actionx-counter-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

.actionx-counter {
  font-size: 16px;
  color: #e6e6e6;
  font-weight: bold; }

.agentur-main-wrap {
  margin-top: calc(100vh - 175px); }

.agentur-bg {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  position: fixed;
  background-position: center;
  background-size: cover;
  top: 0;
  z-index: -2; }

.agentur-text-inner {
  display: none; }

.agentur-text {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }

.agentur-nav {
  position: absolute;
  top: 0; }

.fadeIn {
  opacity: 1; }

.fadeOut {
  opacity: 0; }

.fadeInScale {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.nav-absolute {
  position: absolute;
  top: 0px; }

.team-item-active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  opacity: 1; }

.show-flex {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 9999; }

.menu-item-active {
  color: #f95951; }

.job-arrow-open {
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  transform: rotateZ(180deg); }

.jobs-cat-open {
  background-color: #f95951; }
  .jobs-cat-open h2, .jobs-cat-open polyline {
    color: white;
    stroke: white; }

.agentur-main-wrap-height {
  margin-top: 225px !important; }

.nav-fade-in {
  opacity: 1 !important;
  top: 0 !important; }

.cubeamid {
  background-image: url(../../assets/files/svg/cubamid.svg);
  background-size: cover;
  background-position: center; }

.menu-start-show {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

@media (max-width: 1400px) {
  .news-item, .team-item {
    height: 19.64vw; } }

@media (max-width: 1370px) {
  .news-item {
    margin-right: 10px; }
    .news-item:nth-child(5n) {
      margin-right: 10px; }
    .news-item:nth-child(4n) {
      margin-right: 0; } }

@media (max-width: 1150px) {
  .case-item-wrap:nth-child(3n) {
    margin-right: 10px; }
  .case-item-wrap:nth-child(2n) {
    margin-right: 0; } }

@media (max-width: 1100px) {
  .start-credo-item-text {
    width: 80%;
    max-width: none; }
  .start-credo-item-last {
    border-right: 1px solid white !important; }
  .start-credo-item {
    width: 80vw !important;
    border-bottom: 1px solid white;
    text-align: center; }
    .start-credo-item img {
      margin-left: auto;
      margin-right: auto; }
  .start-credo-item-inner {
    height: 300px !important; } }

@media (max-width: 1060px) {
  .news-item {
    margin-right: 10px; }
    .news-item:nth-child(4n) {
      margin-right: 10px; }
    .news-item:nth-child(3n) {
      margin-right: 0; } }

@media (max-width: 1050px) {
  .team-flex {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .team-item {
    width: 35%;
    height: 26.19vw; }
  .main-header {
    font-size: 8rem; }
  .submain-header {
    font-size: 6rem; }
  body, html {
    font-size: 9px; }
  .cube-button {
    font-size: 18px !important;
    line-height: 50px;
    padding: 0 25px !important;
    width: 235px; } }

@media (max-width: 980px) {
  .nav-logo, .open-menu {
    position: relative; }
  .menu {
    position: fixed;
    height: 100vh;
    width: 100vw;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none; }
  .menu-item {
    clear: both;
    margin: 20px 0; }
  .menu-active-slider {
    display: none; }
  .open-menu, .close-menu, .open-menu-start {
    display: block; } }

@media (max-width: 900px) {
  .table-1 {
    width: 45%; }
  .table-2 {
    width: 55%; } }

@media (max-width: 850px) {
  .team-item {
    width: 46.8%;
    height: 32.35vw; }
  .footer {
    height: 300px; }
  .footer-col-3, .footer-col-2 {
    padding-bottom: 70px; } }

@media (max-width: 750px) {
  .zahlen-item {
    width: 46.72%; }
  .side-padding {
    padding-left: 5%;
    padding-right: 5%; } }

@media (max-width: 700px) {
  .cube-button {
    font-size: 2rem; }
  .team-item {
    width: 232px;
    height: 220px;
    margin: 50px 0 0 0; }
  .team-item-name, .team-item-job {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  body, html {
    font-size: 7.25px; } }

@media (max-width: 650px) {
  .xslider-inner-wrap, .xslider-inner-slide, .xslider-loader-inner {
    max-width: 90vw;
    max-height: 90vw; } }

@media (max-width: 500px) {
  body, html {
    font-size: 6.25px; } }

@media (max-width: 1370px) {
  .news-wrap {
    padding-bottom: 30px; } }

@media (max-width: 992px) {
  .jobs-desc, .kontakt-intro {
    margin-bottom: 60px; } }

@media (max-width: 900px) {
  .xslider-text-subtext br {
    display: none; } }

@media (max-width: 768px) {
  .option-a-wrap, .option-b-wrap, .option-d-wrap {
    margin-bottom: 60px; }
  .option-a-wrap {
    padding-bottom: 0; }
  .option-c-slider {
    padding-bottom: 50px; }
  .intro-bild, .agentur-bg, .main-img {
    height: 50vh;
    min-height: 400px; }
  #kontakt-map {
    min-height: 200px;
    height: 50vh; }
  .agentur-main-wrap {
    margin-top: calc(60vh - 175px); }
  .table-1 {
    width: 100%; }
  .table-2 {
    width: 100%; }
  .padding {
    padding-top: 60px;
    padding-bottom: 40px; }
  .team-inner, .ref-flex {
    padding-bottom: 60px; }
  .padding120 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .cube-button {
    margin-top: 60px; }
  .job-cat-inner {
    padding: 30px 40px; }
  .job-info {
    margin-bottom: 55px; }
  .gf-item {
    width: 100%; }
    .gf-item .team-item {
      max-width: 50vw;
      max-height: 50vw; }
  .nav {
    height: 100px; }
  .close-menu {
    top: 40px;
    right: 5%; }
  .xslider-text-header {
    margin-bottom: 15px; }
  .xslider-text-subtext {
    margin-top: 0; }
  .impressum-tabs {
    margin-top: 60px; }
  .ref-flex-borderfix {
    bottom: 60px; } }

@media (max-width: 750px) {
  .news-item {
    margin-right: 10px; }
    .news-item:nth-child(3n) {
      margin-right: 10px; }
    .news-item:nth-child(2n) {
      margin-right: 0; } }

@media (max-width: 650px) {
  .case-wrapper .slick-dots {
    bottom: 3vw !important; }
    .case-wrapper .slick-dots button {
      width: 16px;
      height: 16px; }
      .case-wrapper .slick-dots button:hover {
        border-width: 8px; }
    .case-wrapper .slick-dots .slick-active button {
      border-width: 8px;
      border-radius: 50%; }
  .option-a-wrap {
    padding-bottom: 30px !important; }
  .option-a-link {
    padding: 25px 30px; }
  .option-c-slider .slick-dots {
    bottom: 60px !important; }
  .main-header {
    font-size: 8.9vw; }
  .job-item {
    font-size: 3.9vw;
    padding-left: 12vw;
    padding-right: 12vw; }
  .job-list-wrap {
    padding: 60px; }
  .job-info {
    margin-top: 25px; }
  .job-inner h1 {
    margin-bottom: 30px; }
  .footer-col-1 {
    display: none; }
  .footer-col-2 {
    width: 35%; }
  .footer-col-3 {
    width: 65%; } }

@media (max-width: 600px) {
  .case-nav {
    width: 100%;
    position: relative; }
    .case-nav svg {
      width: 60px;
      height: 60px; }
  .case-close svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .case-prev-next {
    height: 60px;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .case-prev {
    margin-right: 90px;
    width: 60px;
    height: 60px; }
  .case-next {
    margin-left: 90px;
    width: 60px;
    height: 60px; } }

@media (max-width: 555px) {
  .case-item-wrap:nth-child(3n) {
    margin-right: 0; }
  .case-item-wrap:nth-child(2n) {
    margin-right: 0; }
  .case-item-wrap:nth-child(1n) {
    margin-right: 0; } }

@media (max-width: 550px) {
  .job-cat-title {
    font-size: 5vw; }
  .job-item {
    padding-left: 30px; }
  .job-cat-inner {
    padding-left: 15px;
    padding-right: 15px; }
  .job-arrow {
    width: 20.66px;
    height: 10.66px; }
  .job-item-padding {
    padding: 0px 0 5.5vw 0; }
  .job-list-wrap {
    padding: 10vw;
    margin-bottom: 40px; }
  .gf-xing {
    padding-top: 20px;
    padding-bottom: 25px;
    margin-top: 20px; }
  .gf-name {
    margin-top: 5px; }
  .cube-button {
    line-height: 60px; } }

@media (max-width: 515px) {
  .team-item {
    margin-top: 10px; } }

@media (max-width: 500px) {
  .team-item-name, .team-item-job {
    font-size: 2.2rem; } }

@media (max-width: 450px) {
  .start-credo-item-inner {
    height: 400px !important; }
  .main-subheader {
    margin-top: 30px; }
  .job-list-wrap {
    margin-bottom: 6.66vw; }
  .case-nav {
    width: 100%;
    position: relative; }
    .case-nav svg {
      width: 50px;
      height: 50px; }
  .case-close svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .case-prev-next {
    height: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .case-prev {
    margin-right: 70px;
    width: 50px;
    height: 50px; }
  .case-next {
    margin-left: 70px;
    width: 50px;
    height: 50px; } }

@media (max-width: 400px) {
  .nav img {
    width: 65vw;
    height: 6.25vw; }
  .news-item {
    margin-right: 0; }
    .news-item:nth-child(2n) {
      margin-right: 0; }
    .news-item:nth-child(1n) {
      margin-right: 0; } }

@media (max-height: 600px) {
  .agentur-main-wrap {
    margin-top: 600px; } }

@-webkit-keyframes uil-rolling-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-rolling-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-rolling-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes uil-rolling-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes uil-rolling-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes uil-rolling-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes uil-rolling-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes uil-rolling-anim {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.uil-rolling-css {
  width: 200px;
  height: 200px; }

.uil-rolling-css > div {
  width: 200px;
  height: 200px;
  position: relative;
  -ms-animation: uil-rolling-anim 1s linear infinite;
  -moz-animation: uil-rolling-anim 1s linear infinite;
  -webkit-animation: uil-rolling-anim 1s linear infinite;
  -o-animation: uil-rolling-anim 1s linear infinite;
  animation: uil-rolling-anim 1s linear infinite; }

.uil-rolling-css > div div {
  position: absolute;
  width: 200px;
  height: 100px;
  border-radius: 1000px 1000px 0 0;
  border-color: #414141;
  border-style: solid;
  border-width: 40px;
  border-bottom-width: 0; }

.uil-rolling-css > div div:nth-of-type(2) {
  -ms-transform: translate(0, 50px) rotate(54deg) translate(0, -50px);
  -moz-transform: translate(0, 50px) rotate(54deg) translate(0, -50px);
  -webkit-transform: translate(0, 50px) rotate(54deg) translate(0, -50px);
  -o-transform: translate(0, 50px) rotate(54deg) translate(0, -50px);
  transform: translate(0, 50px) rotate(54deg) translate(0, -50px); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }
