.kontakt-intro {
    text-align: center;
    margin-bottom: 100px; }

.kontakt-back {
    margin-top: 100px; }


.table-wrap {
    max-width: 1000px;
    margin: 0 auto;
    @include display(flex);
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    td {
        border: none;
        font-size: 2.4rem;
        padding: 0; } }

.table-1 {
    width: 55%; }
.table-2 {
    width: 45%; }
.col-3 {
    width: 67.5%;
    a {
        color: $cube-salmon; } }

.kontakt {
    .padding120 {
        padding-top: 100px; } }
