.cases {
    padding-bottom: 110px; }
.cases-inner {
    @include display(flex);
    @include flex-wrap(wrap);

    width: 100%; }


.case-item {
    position: relative;
    cursor: pointer;
    width: 100%;
    img {
        display: block;
        width: 100%; } }

.case-item-wrap {
    width: 30%;
    margin-bottom: 10px;
    margin-right: 10px;
    &:nth-child(3n) {
        margin-right: 0; } }
.case-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    opacity: 0;
    @include transition(opacity 300ms);
    color: white;
    text-transform: uppercase;
    line-height: .8;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    padding: 8%;
    h2 {
        margin-bottom: 2.63%;
        font-family: 'DIN_1451 LT W01 Engschrift'; }
    div {
        line-height: 1.2;
        text-transform: none; } }

.case-item-hover:hover {
    .case-item-overlay {
        opacity: 1; } }
