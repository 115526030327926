.job-inner {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 110px;
    .submain-header {
        margin-bottom: 45px; }

    h1, h2 {
        text-align: center;
        line-height: 1;
        text-transform: uppercase; }
    h1 {
        margin-bottom: 55px; }
    h2 {
        margin-bottom: 15px;
        font-family: 'DIN Next W01 Regular';
        text-transform: none; }
    h3 {
        text-transform: uppercase; } }


.job-date {
    line-height: .8;
    font-style: italic;
    text-align: center;
    font-family: 'Sabon Next W01 Italic'; }

.job-info {
    margin-top: 50px;
    margin-bottom: 110px; }

.job-list-wrap {
    background-color: $cube_salmon;
    width: 100%;
    color: white;
    padding: 80px;
    margin-bottom: 60px;
    a {
        color: white;
        &:hover {
            text-decoration: underline; } }
    h3 {
        font-family: 'DIN_1451 LT W01 Engschrift'; } }

.job-list {
    list-style: disc;
    margin-left: 1.8rem; }

.job-back {
    color: $cube-salmon; }
