.nav-font {
    font-size: 18px; }

.main-header {
    font-size: 8rem;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    color: $cube-salmon;
    font-weight: normal;
    margin: 0 0 30px 0;
    font-family: 'DIN_1451 LT W01 Engschrift'; }

.submain-header {
    font-size: 8rem;
    line-height: 1.2;
    margin: 0;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    color: $cube-grey-dark;
    font-family: 'DIN_1451 LT W01 Engschrift'; }

.main-subheader {
    font-size: 1.8rem;
    line-height: 1.5;
    text-align: center;
    margin-top: 40px;

    p {
        margin: 0;
        color: $cube-grey-dark; } }

.h-35 {
    font-size: 3.5rem; }

.h-small {
    font-size: 4rem; }

.h-smaller {
    font-size: 2.6rem; }

.h-smallest {
    font-size: 2rem; }

.p-normal {
    font-size: 1.8rem;
    line-height: 1.5; }

.p-smaller {
    font-size: 2rem;
    line-height: 1.5; }
