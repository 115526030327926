.footer {
    width: 100%;
    height: 380px;
    background-color: $cube-grey-dark; }

.footer-inner {
    width: 100%;
    height: 100%;
    max-width: 1220px;
    margin: 0 auto;
    @include display(flex); }

.footer-col {
    width: 33.33%;
    height: 100%;
    @include display(flex);
    @include align-items(center);
    a {
        color: white; }

    li {
        text-transform: uppercase;
        font-size: 16px;
        line-height: 2;
        font-weight: 500;
        font-family: 'DIN_1451 LT W01 Mittelschrift'; } }

.footer-logo {
    width: 146px;
    height: 15px; }

.footer-socials {
    margin-bottom: 45px;
    li {
        float: left;
        margin-left: 20px; } }

.footer-col-2 {
    @include flex-direction(column);
    @include justify-content(flex-end);
    padding-bottom: 110px; }


.footer-col-3 {
    @include flex-direction(column);
    @include justify-content(flex-end);
    padding-bottom: 110px;
    @include align-items(flex-end); }

.footer-info {
    text-align: right;
    color: white;
    font-size: 14px;
    color: rgb(140, 140, 140);
    a {
        color: rgb(140, 140, 140);
        &:hover {
            color: $cube-salmon; } } }
