.news-wrap {
    width: 100%;
    padding-bottom: 110px; }

.news {
    @include display(flex);
    @include flex-wrap(wrap);

    width: 100%; }

.news-item {
    width: 21.82%;
    height: 275px;
    position: relative;
    display: block;
    margin-bottom: 10px;
    margin-right: 10px;
    &:nth-child(5n) {
        margin-right: 0; }
    &:hover {
        .news-item-overlay {
            opacity: 1; } } }

.news-item-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }

.news-item-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include display(flex);
    @include justify-content(center);
    @include align-items(center);
    opacity: 0;
    background-color: rgba(0, 0, 0, .6);
    @include transition(opacity 300ms); }

.fb-news {
    width: 18.6%;
    height: 18.6%; }

.fb-loader-wrap {
    margin: 20vh auto 20vh auto;
    width: 75px;
    height: 75px;
    svg {
        width: 100%;
        height: 100%; } }
.fb-loader {
    path {
        @include animation(fb-loading 2s ease-in infinite); } }

.news-loading {
    width: 100%; }


@include keyframes(loading) {
    from {
        @include transform(translateX(-100%)); }

    to {
        @include transform(translateX(500%)); } }

@include keyframes(fb-loading) {
    from {
        fill: $cube-salmon; }
    50% {
        fill: white; }
    to {
        fill: $cube-salmon; } }

