.clip-svg {
    position: absolute; }

.max-width {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto; }

.max-width-flex {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto; }

.padding {
    padding: 120px 0 60px 0; }

.padding120 {
    padding: 120px 0; }

.side-padding {
    padding: 0 70px; }

.fortschritt {
    height: 5px;
    background-color: $cube-salmon;
    width: 0%;
    position: fixed;
    top: 0;
    z-index: 99999; }

.home-fortschritt {
    height: 5px;
    background-color: $cube-salmon;
    width: 0%;
    position: fixed;
    top: 0;
    z-index: 99999; }

.cube-button {
    display: block;
    color: $cube-salmon;
    width: 275px;
    font-size: 2rem;
    line-height: 75px;
    padding: 0 45px;
    border: 1px solid $cube-salmon;
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;
    @include transition(color 400ms);
    margin: 120px auto 0 auto;
    font-family: 'DIN_1451 LT W01 Engschrift';
    span {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 0%;
        background-color: $cube-salmon;
        @include transition(width 400ms); }
    div {
        position: relative;
        z-index: 99;
        text-align: center; }
    &:hover {
        color: white !important;
        span {
            width: 100%; } }
    &:focus {
        color: $cube-salmon; } }


.intro-text {
    margin: auto auto 120px auto; }

.intro-bild {
    width: 100%;
    height: 42.86vw;
    max-height: 771px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }

.start-main-wrap {
    z-index: 99;
    nav {
        @include transition(opacity 300ms);
        position: fixed;
        top: -100px;
        opacity: 0;
        z-index: 0; } }

.menu-start {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    display: none; }

.close-menu-start {
    position: absolute;
    top: 35px;
    right: 5%;
    background: none !important;
    border-radius: 0;
    padding: 0; }

.open-menu-start {
    width: 25px;
    height: 25px;
    background: none !important;
    border-radius: 0;
    padding: 0;
    display: none;
    svg {
        width: 100%;
        height: 100%; } }
